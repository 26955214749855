import {
  FormControl,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import theme from 'src/style-system/theme';

const RadioField = (props: any) => {
  const { data, onClickRadioButton, value } = props;

  const handleChange = (value: string) => {
    onClickRadioButton(value);
  };
  const covertToAddress = (item: any) => {
    const {
      first_name,
      last_name,
      address_line_1,
      address_line_2,
      zip_code,
      phone_number,
      city,
      state,
    } = item;
    return (
      <>
        <div>
          {first_name} {last_name}
        </div>
        <div>{address_line_1}</div>
        <div>{address_line_2}</div>
        <div>
          {city}, {state}, {zip_code}
        </div>
        <div>{phone_number}</div>
      </>
    );
  };
  return (
    <>
      <FormControl>
        {data.map((item: any) => (
          <RadioGroup
            aria-labelledby="address-radio-buttons"
            name="address"
            onChange={() => handleChange(item.id)}
            value={value}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'start',
                marginBottom: '10px',
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={value === item.id}
                    sx={{
                      '&, &.Mui-checked': {
                        color: theme.palette.cumin.main,
                        transform: 'scale(0.8)',
                      },
                    }}
                  />
                }
                label=""
                sx={{ margin: 0 }}
              />
              <Typography
                variant="caption"
                color="cumin.main"
                sx={{ marginTop: '10px' }}
                align="left"
              >
                {covertToAddress(item)}
              </Typography>
            </div>
          </RadioGroup>
        ))}
      </FormControl>
    </>
  );
};

export default RadioField;
