import { makeVar, useReactiveVar } from '@apollo/client';
import { TfrequenciesV2, plansType } from '@utils/types';
import { useEffect } from 'react';

// This store is used to store all freq and plan data
export interface planAndFreqState {
  plans: plansType[];
  frequencies: string[];
  frequenciesV2: TfrequenciesV2[];
}

export const fetchPlanAndFreqVar = makeVar<planAndFreqState>({
  plans: [],
  frequencies: [],
  frequenciesV2: [],
});

export const updateFetchFreq = (freq: string[]) => {
  fetchPlanAndFreqVar({
    ...fetchPlanAndFreqVar(),
    frequencies: freq,
  });
};

export const updateFetchPlan = (plans: plansType[]) => {
  fetchPlanAndFreqVar({
    ...fetchPlanAndFreqVar(),
    plans,
  });
};
