import { Box } from '@mui/material';
import Image from 'next/image';

import tCCIcon from '@public/assets/png/tCCIcon.png';

interface ITCCLogo {
  width?: string;
  height?: string;
}

const TCCLogo = ({ width = '40px', height = '40px' }: ITCCLogo) => {
  return (
    <Box sx={{ position: 'relative', width: width, height: height }}>
      <Image src={tCCIcon} alt="tCCIcon" objectFit="contain" layout="fill" />
    </Box>
  );
};

export default TCCLogo;
