import { Box, Button, FormControl, Input, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';
import PageLoader from '@components/PageLoader';
import useWindowDimensions from '@utils/useWindowDimensions';

const promoInput = (props: any) => {
  const {
    name,
    label,
    value,
    error,
    onChange,
    placeholder,
    onClickApply,
    isPromoApplied = false,
    loading = false,
  } = props;

  const { width } = useWindowDimensions();

  return (
    <>
      <FormControl
        variant="standard"
        sx={{ position: 'relative', width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          {isPromoApplied ? (
            <Box
              sx={{
                flexGrow: 1,
                padding: '11px 25px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                columnGap: '10px',
                background: '#FBFAFA',
                border: `1px solid ${theme.palette.offWhite.main}`,
                borderRadius: '25px 0 0 25px',
                color: theme.palette.green.main,
                borderRight: 'none',
                overflow: 'hidden',
              }}
            >
              <CheckCircleIcon />
              <Typography
                variant="body2"
                align="left"
                color="green.main"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {value}
              </Typography>
            </Box>
          ) : (
            <Input
              disableUnderline
              value={value}
              onChange={onChange}
              name={name}
              placeholder={placeholder}
              sx={{
                padding: '5px 25px',
                border: 'none',
                backgroundColor: 'white',
                // borderRadius: '25px',
                outline: 0,
                color: 'cumin.main',
                fontSize: '16px !important',
                width: '80%',
                height: '50px',
                borderTopLeftRadius: '25px',
                borderBottomLeftRadius: '25px',
              }}
            />
          )}

          <Button
            color="turmeric"
            variant="contained"
            sx={{
              borderTopRightRadius: '25px !important',
              borderBottomRightRadius: '25px !important',
              textTransform: 'none !important',
              width: '20%',
              height: '47px',
              '&:disabled': {
                backgroundColor: theme.palette.turmeric.main,
              },
            }}
            onClick={onClickApply}
            disabled={loading}
          >
            <Typography
              variant="caption"
              align="center"
              color="garamMasala.main"
              sx={{ display: 'flex' }}
            >
              {isPromoApplied ? (
                <CloseIcon
                  sx={{
                    color: theme.palette.garamMasala.main,
                    fontSize: 'large',
                    cursor: 'pointer',
                    transform: 'scale(1.7)',
                  }}
                />
              ) : (
                'Apply'
              )}
            </Typography>
          </Button>
        </Box>

        {error && (
          <Typography variant="caption" color="black">
            {error}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

export default promoInput;
