// essentials
import React, { useEffect } from 'react';
import useStyles from './useStyles';
// =======================================

// helpers
// import _ from 'lodash';
// =======================================

// mui
import { IconButton, Snackbar, Portal, Typography, Box } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { snackbarInfo } from 'store/utils';
import {
  WarningAmberRounded,
  CheckCircleOutline,
  Close,
} from '@mui/icons-material';
import theme from 'src/style-system/theme';
// =======================================

// redux
// import { RootStateOrAny, useSelector } from 'react-redux';
// =======================================

export interface AlertMessage {
  message: string;
  key: number;
  type: any;
}

export interface State {
  open: boolean;
  messageStack: readonly AlertMessage[];
  messageInfo?: AlertMessage;
}

const iconMap: any = {
  warning: (
    <WarningAmberRounded
      sx={{
        color: '#e6544d',
      }}
    />
  ),
  success: (
    <CheckCircleOutline
      sx={{
        color: '#65d247',
      }}
    />
  ),
  error: (
    <CheckCircleOutline
      sx={{
        color: '#FFF2D6',
      }}
    />
  ),
};

const ConsecutiveSnackbars = () => {
  const classes = useStyles();

  // local state
  const [messageStack, setMessageStack] = React.useState<
    readonly AlertMessage[]
  >([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    AlertMessage | undefined
  >(undefined);
  // =======================================
  // mapStateToProps
  const toastInfo = useReactiveVar(snackbarInfo);
  // =======================================

  useEffect(() => {
    if (toastInfo) {
      setMessageStack((prev) => [
        ...prev,
        { ...toastInfo, key: new Date().getTime() },
      ]);
    } else {
      setOpen(false);
    }
  }, [toastInfo]);

  useEffect(() => {
    if (messageStack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...messageStack[0] });
      setMessageStack((prev) => prev.slice(1));
      setOpen(true);
    } else if (messageStack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [messageStack, messageInfo, open]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    snackbarInfo(null);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const icon = iconMap[toastInfo?.type];

  return (
    <Portal>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.snackbar}
      >
        <div className={classes.toastContainer}>
          {/* <div className={classes.leftIcon}>{icon}</div> */}
          {toastInfo ? (
            <Box className={classes.textContainer}>
              {toastInfo?.title ? (
                <Typography
                  variant="body1"
                  align="left"
                  color={theme.palette.garamMasala.main}
                >
                  {toastInfo?.title}
                </Typography>
              ) : null}
              <Typography
                variant={'body1'}
                align="left"
                color={theme.palette.cumin.main}
              >
                {toastInfo?.message}
              </Typography>
            </Box>
          ) : null}
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close sx={{ color: theme.palette.garamMasala.main }} />
          </IconButton>
        </div>
      </Snackbar>
    </Portal>
  );
};

export default ConsecutiveSnackbars;
