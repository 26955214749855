import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
const fontFamily = [
  'Spoof-Regular',
  'Spoof-Medium',
  'Spoof-Bold',
  'Gela',
  'sans-serif',
  'Dharma-Gothic-E',
].join(',');

const defaultTheme = createTheme();
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
      contrastText: '#469178',
    },
    error: {
      main: red.A400,
    },
    garamMasala: {
      main: '#620A15',
      light: '#A34853',
      dark: '#45080F',
      contrastText: '#F7C144',
    },
    turmeric: {
      main: '#F7C144',
      light: '#FCD376',
      dark: '#F5AE0A',
      contrastText: '#620A15',
    },
    cinnamon: {
      main: '#D46A19',
      light: '#E07B2E',
      dark: '#A05013',
      contrastText: '#F0EAE3',
    },
    whitePepper: {
      main: '#F0EAE3',
      light: '#FFFAF4',
      dark: '#E4DACD',
      contrastText: '#D46A19',
    },
    cumin: {
      main: '#B38552',
      light: '#D1A574',
      dark: '#A07646',
      contrastText: '#F7C144',
    },
    lightGray: {
      main: '#D2D2D2',
      light: '#D2D2D2',
      dark: '#BFBFBF',
      contrastText: '#828282',
    },
    offWhite: {
      main: '#EAE2D7',
      light: '#EDE7DE',
      dark: '#DCCFBC',
    },
    green: {
      main: '#469178',
      light: '#469178',
      dark: '#469178',
    },
    red: {
      main: '#EB4242',
      light: '#EB4242',
      dark: '#EB4242',
    },
    light1: {
      main: '#FBF9F7',
      light: '#FBF9F7',
      dark: '#FBF9F7',
    },
    light2: {
      main: '#F6F2EE',
      light: '#F6F2EE',
      dark: '#F6F2EE',
    },
    dark3: {
      main: '#47070F',
      light: '#47070F',
      dark: '#47070F',
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontFamily: 'Gela',
      fontWeight: 700,
      fontSize: '51px',
      lineHeight: '59px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '43px',
        lineHeight: '49px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '34px',
        lineHeight: '38px',
      },
    },

    h2: {
      fontFamily: 'Gela',
      fontWeight: 700,
      fontSize: '36px',
      lineHeight: '42px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '30px',
        lineHeight: '35px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },

    h3: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '48px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },

    h4: {
      fontFamily: 'Spoof-medium',
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '32px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },

    body1: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '20px',
        lineHeight: '26px',
      },
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },

    body2: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 400,
      fontSize: '21px',
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },

    button1: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },

    button2: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },

    button3: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '28px',

      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },

    buttonSmall: {
      fontFamily: 'Spoof-Medium',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '24px',

      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '20px',
        lineHeight: '25px',
      },

      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '16px',
      },
    },

    caption: {
      fontFamily: 'Spoof-Regular',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '24px',
      [defaultTheme.breakpoints.down('lg')]: {
        fontSize: '15px',
        lineHeight: '20px',
      },
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
    },

    MuiGrid: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          margin: 0,
          width: '100%',
          '& > .MuiGrid-item': {
            padding: 0,
          },
          // marginLeft: '0',
          // marginTop: '0',

          // columnSpacing: '25px !important',

          // '&.MuiGrid-item': {
          //   paddingLeft: '0 !important', //use important because be default there is padding top and left at grid item
          //   paddingTop: '0 !important',
          // },
        },
      },
    },
  },
});

export default theme;
