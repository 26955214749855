import { useState } from 'react';
import {
  FormControl,
  Typography,
  Checkbox as MuiCheckbox,
  Box,
} from '@mui/material';
import theme from 'src/style-system/theme';

const checkbox = (props: any) => {
  const [isChecked, setChecked] = useState<boolean>(false);
  const { name, label, value, error, onChange, subLabel } = props;

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    setChecked(event.target.checked);
  };

  return (
    <>
      <FormControl
        variant="standard"
        sx={{
          width: '100%',
          position: 'relative',
          marginBottom: '25px',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          columnGap: '10px',
        }}
      >
        <MuiCheckbox
          name={name}
          checked={value}
          onChange={onChecked}
          sx={{
            padding: '0px',
            //paddingTop: '5px',
            color: error ? 'red' : 'white', //TODO: it is not final either to show msg or checkbox color change
            '&.Mui-checked': {
              color: 'white',
            },
          }}
          id={name}
        />
        <label
          htmlFor={name}
          style={{ cursor: 'pointer' }}
          onClick={() => setChecked(!isChecked)}
        >
          <Box>
            <Typography
              component="div"
              variant="caption"
              align="justify"
              color="cumin.main"
              mb={1}
            >
              {label}
            </Typography>
            {subLabel ? (
              <Typography
                component="div"
                variant="caption"
                color="cumin.main"
                align="justify"
              >
                {subLabel}
              </Typography>
            ) : null}
          </Box>
        </label>
        {/* TODO: it is not final either to show msg or checkbox color change */}
        {/* {error && (
          <Typography variant="caption" color="black">
            {error}
          </Typography>
        )} */}
      </FormControl>
    </>
  );
};

export default checkbox;
