import { gql } from '@apollo/client';

export const PAGE_INFO_FIELDS = gql`
  fragment PageInfoFields on PageInfo {
    end_cursor
    start_cursor
    has_previous_page
    has_next_page
  }
`;
export const PAYMENT_METHOD_FIELDS = gql`
  fragment PaymentMethodFields on PaymentMethod {
    id
    name
    expiry_year
    expiry_month
    last4
    brand
  }
`;

export const ORDER_PRODUCTS_FIELDS = gql`
  fragment OrderProductFields on OrderProduct {
    quantity
    product {
      id
      title
      taste
      product_badge
      image_url
      requires
      chef_specials
    }
  }
`;

export const ORDER_PRODUCTS_FIELDS_NEW = gql`
  fragment OrderProductFieldsNew on OrderProduct {
    quantity
    product {
      id
      title
      taste
      product_badge
      image_url
      requires
      chef_specials
      rating
    }
  }
`;

export const ORDER_SIDE_PRODUCTS_FIELDS = gql`
  fragment OrderSideProductFields on OrderProduct {
    product {
      id
      title
      taste
      product_badge
      chef_specials
      image_url
      secondaryImages
      isActive
      rating
    }
    quantity
  }
`;

export const PANTRY_PRODUCTS_FIELDS = gql`
  fragment PantryProductFields on OrderProduct {
    product {
      id
      title
      taste
      chef_specials
      product_badge
      rating
      image_url
      secondaryImages
      isActive
    }
    quantity
  }
`;

export const SHIPPING_ADDRESS_FIELDS = gql`
  fragment ShippingAddressFields on Address {
    id
    city
    address_line_2
    address_line_1
    last_name
    first_name
    state
    country_code
    zip_code
    phone_number
  }
`;

export const SCHEDULE_BASIC_DETAILS_FRAGMENT = gql`
  fragment ScheduleBasicDetailsItem on Schedule {
    id
    subscription_id
    schedule_date
    total_items
    total_price
    is_active
    currencySymbol
    mealPrice
    pantryPrice
    sidePrice
    isShopify
  }
`;

export const GIFT_BOX_DETAILS = gql`
  fragment GiftBoxFields on Giftbox {
    title
  }
`;

export const ORDER_SUBSCRIPTION_PRODUCTS_FIELDS = gql`
  fragment OrderSubscriptionProductFields on OrderProduct {
    quantity
    product {
      id
      title
      description
      taste
      vegan
      spice_level
      product_badge
      image_url
      requires
      chef_specials
      filter_ids
      rating
    }
  }
`;

export const ORDER_BASIC_DETAILS_FRAGMENT = gql`
  fragment OrderBasicDetailsItem on Order {
    id
    subscription_id
    schedule_date
    total_items
    total_price
    subTotalPrice
    tax
    taxPantry
    discounts
    discountsPantry
    cuminCreditsPantry
    appliedCuminCredits
    orderPrice
    orderPricePantry
    orderPriceSide
    fulfillmentStatus
    status
    trackingId
    createdAt
    currencySymbol
    shippingUrl
    orderType
    planGroup
    isShopify
    externalId
  }
`;

export const SCHEDULE_DETAILS_FRAGMENT = gql`
  fragment ScheduleDetailsItem on Schedule {
    ...ScheduleBasicDetailsItem
    shipping_address {
      ...ShippingAddressFields
    }
    order_products {
      ...OrderProductFieldsNew
    }
    pantryProducts {
      ...PantryProductFields
    }
    sideProducts {
      ...OrderSideProductFields
    }
    payment_method {
      ...PaymentMethodFields
    }
  }
  ${SCHEDULE_BASIC_DETAILS_FRAGMENT}
  ${SHIPPING_ADDRESS_FIELDS}
  ${ORDER_PRODUCTS_FIELDS_NEW}
  ${PANTRY_PRODUCTS_FIELDS}
  ${ORDER_SIDE_PRODUCTS_FIELDS}
  ${PAYMENT_METHOD_FIELDS}
`;

export const ORDER_DETAILS_FRAGMENT = gql`
  fragment OrderDetailsItem on Order {
    ...OrderBasicDetailsItem
    shipping_address {
      ...ShippingAddressFields
    }
    order_products {
      ...OrderSubscriptionProductFields
    }
    sideProducts {
      ...OrderSideProductFields
    }
    pantryProducts {
      ...PantryProductFields
    }
    payment_method {
      ...PaymentMethodFields
    }
  }
  ${ORDER_BASIC_DETAILS_FRAGMENT}
  ${SHIPPING_ADDRESS_FIELDS}
  ${ORDER_SUBSCRIPTION_PRODUCTS_FIELDS}
  ${PANTRY_PRODUCTS_FIELDS}
  ${ORDER_SIDE_PRODUCTS_FIELDS}
  ${PAYMENT_METHOD_FIELDS}
`;
