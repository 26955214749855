import { gql } from 'graphql-tag';
import { client } from '..';
import {
  PAGE_INFO_FIELDS,
  PAYMENT_METHOD_FIELDS,
  ORDER_PRODUCTS_FIELDS,
  SHIPPING_ADDRESS_FIELDS,
  GIFT_BOX_DETAILS,
} from '@graphql/fragments/order';

export const GET_USER_QUERY = gql`
  query {
    getMe {
      id
      email
      firstname
      lastname
      totalSpent
      totalOrders
      name
      isAccountActivated
      admin
      referral_code
      cuminCredits
      creditsCurrencySymbol
      creditsCurrency
      failedSubscriptionId
      isTutorialComplete
      address_list {
        address_line_1
        address_line_2
        city
        country_code
        first_name
        id
        last_name
        phone_number
        state
        zip_code
        stateName
      }
    }
  }
`;

export const GET_PAST_ORDERS_QUERY = gql`
  query getPastOrders($first: Int, $after: String, $filters: AllOrdersFilter) {
    getAllOrders {
      id
      pastOrders(first: $first, after: $after, filters: $filters) {
        total_count
        edges {
          node {
            id
            subscription_id
            schedule_date
            total_items
            total_price
            fulfillmentStatus
            status
            createdAt
            orderType
            currencySymbol
            isShopify
            externalId
            shippingUrl
            shipping_address {
              ...ShippingAddressFields
            }
            order_products {
              ...OrderProductFields
            }
            payment_method {
              ...PaymentMethodFields
            }
            giftbox {
              ...GiftBoxFields
            }
          }
          cursor
        }
        page_info {
          ...PageInfoFields
        }
      }
    }
  }
  ${PAGE_INFO_FIELDS}
  ${PAYMENT_METHOD_FIELDS}
  ${ORDER_PRODUCTS_FIELDS}
  ${SHIPPING_ADDRESS_FIELDS}
  ${GIFT_BOX_DETAILS}
`;

export const GET_CURRENT_ORDERS_QUERY = gql`
  query getCurrentOrders($filters: AllOrdersFilter) {
    getAllOrders {
      id
      currentOrders(filters: $filters) {
        id
        subscription_id
        schedule_date
        total_items
        total_price
        fulfillmentStatus
        status
        createdAt
        isShopify
        externalId
        shipping_address {
          ...ShippingAddressFields
        }
        order_products {
          ...OrderProductFields
        }
        payment_method {
          ...PaymentMethodFields
        }
      }
    }
  }
  ${PAYMENT_METHOD_FIELDS}
  ${ORDER_PRODUCTS_FIELDS}
  ${SHIPPING_ADDRESS_FIELDS}
`;

export const GET_UPCOMING_ORDERS_QUERY = gql`
  query getUpcomingOrders(
    $first: Int
    $after: String
    $filters: AllOrdersFilter
  ) {
    getAllOrders {
      id
      upcomingOrders(first: $first, after: $after, filters: $filters) {
        total_count
        edges {
          cursor
          node {
            id
            subscription_id
            schedule_date
            total_items
            total_price
            shipping_address {
              ...ShippingAddressFields
            }
            order_products {
              ...OrderProductFields
            }
            payment_method {
              ...PaymentMethodFields
            }
            is_active
            status
          }
        }
        page_info {
          ...PageInfoFields
        }
      }
    }
  }
  ${PAGE_INFO_FIELDS}
  ${PAYMENT_METHOD_FIELDS}
  ${ORDER_PRODUCTS_FIELDS}
  ${SHIPPING_ADDRESS_FIELDS}
`;

export const GET_USER_ADDRESSES = gql`
  query getUserAddress {
    getUserAddressList {
      id
      first_name
      last_name
      address_line_1
      address_line_2
      city
      state
      country_code
      zip_code
      phone_number
    }
  }
`;

export const getUser = () => {
  return client.query({
    query: GET_USER_QUERY,
    fetchPolicy: 'network-only',
  });
};
