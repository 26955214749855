import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import PageLoader from './PageLoader';
import { getUser } from '@graphql/queries/user';
import { AuthProvider } from '@utils/authContext';
import { AUTO_DETECT_COUNTRY_URL } from '@utils/data';
import { countryList } from '@utils/constant/countryList';
import { sourceUrlVar } from 'store/utils';

interface IAuthState {
  token?: String;
  user?: any;
}

export default function ClientOnly({ children }: any) {
  const [hasMounted, setHasMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenBeingValidated, setTokenBeingValidated] = useState<boolean>(true);
  const [detectedCountryCode, setDetectedCountryCode] = useState<string>('');

  useEffect(() => {
    const url = window.location.href;

    sourceUrlVar(url);

    setHasMounted(true);
    checkForTokenValidation();
    autoCountryDetect(); // for auto country detection
  }, []);

  const autoCountryDetect = async () => {
    try {
      const detectedCountry = await fetch(AUTO_DETECT_COUNTRY_URL);
      const detectedCountryResponse = await detectedCountry.json();
      if (detectedCountryResponse?.country_code_iso3) {
        const isServicableCountry = countryList.find(
          (country) =>
            country.value ===
            detectedCountryResponse?.country_code_iso3.toLowerCase()
        );

        const countryCode = isServicableCountry
          ? isServicableCountry.value
          : 'usa';
        setDetectedCountryCode(countryCode);
      }
    } catch (error) {
      setDetectedCountryCode('usa');
      console.log(error);
    }
  };

  Router.events.on('routeChangeStart', () => {
    setLoading(true);
  });

  Router.events.on('routeChangeComplete', () => {
    setLoading(false);
  });

  Router.events.on('routeChangeError', () => {
    setLoading(false);
  });

  // === auth
  const [authState, setAuthState] = React.useState<IAuthState>({
    token: '',
  });
  const setUserAuthInfo = (data: IAuthState) => {
    setAuthState(data);
  };
  // checks if the user is authenticated or not
  const isUserAuthenticated = () => {
    return !!authState?.token;
  };
  // ========

  const checkForTokenValidation = async () => {
    const token = localStorage.getItem('tcc-jwt-token');
    if (!token) {
      setTokenBeingValidated(false);
      return;
    }
    try {
      const res = await getUser();
      const userData = res?.data?.getMe;
      setAuthState({ token, user: userData });
    } catch (error) {
      // error
      localStorage.removeItem('tcc-jwt-token');
      setAuthState({});
    } finally {
      setTokenBeingValidated(false);
    }
  };

  if (!hasMounted) {
    return null;
  }

  if (loading || tokenBeingValidated) return <PageLoader />;

  return (
    <AuthProvider
      value={{
        authState,
        detectedCountryCode,
        setCountryCode: (countryCode: string) =>
          setDetectedCountryCode(countryCode),
        setAuthState: (userAuthInfo: IAuthState) =>
          setUserAuthInfo(userAuthInfo),
        isUserAuthenticated,
      }}
    >
      <div>{children}</div>
    </AuthProvider>
  );
}
