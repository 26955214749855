import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

const useStyles = makeStyles(() => ({
  snackbar: {
    width: '99%',
    zIndex: 9999999999,
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  container: {},
  toastContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FFF2D6',
    boxShadow: '0 1px 30px 0 rgba(0, 0, 0, 0.05)',
    padding: '18px 24px',
    width: '100%',
    minHeight: '110px',
    height: 'auto',
  },

  leftIcon: {
    '& > svg': {
      width: 36,
      height: 36,
    },
  },
  closeIcon: {
    alignSelf: 'flex-start',
  },

  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    rowGap: '10px',
  },
}));

export default useStyles;
