import { Box, FormControl, Input, Typography } from '@mui/material';
import { useMemo } from 'react';
import theme from 'src/style-system/theme';

export const phoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);

const MobileInputField = (props: any) => {
  const {
    id,
    name,
    label,
    value = '',
    error,
    onChange,
    placeholder,
    className,
    type,
    autoComplete,
    disabled = false,
    onBlur = () => {},
    countryCode,
  } = props;

  // for format mobile number
  const getFormattedNumber = useMemo(() => {
    if (
      name === 'phone_number' &&
      (countryCode === 'can' || countryCode === 'usa' || countryCode === 'gbr')
    ) {
      const phoneNumber = value.toString();
      if (phoneNumber.length === 3) {
        return value.replace(/(\d{3})/, '($1)');
      } else if (phoneNumber.length === 8) {
        const phoneRegex1 = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})$/);
        return value.replace(phoneRegex1, '($1) $2');
      } else if (phoneNumber.length > 8) {
        return value.replace(phoneRegex, '($1) $2-$3');
      } else {
        return value;
      }
    }
  }, [value, name === 'phone_number', countryCode]);

  const getCode = useMemo(() => {
    if (countryCode === 'can' || countryCode === 'usa') {
      return '+1';
    } else if (countryCode === 'gbr') {
      return '+44';
    } else {
      return '+91';
    }
  }, [countryCode]);

  return (
    <>
      <FormControl
        variant="standard"
        className={className}
        sx={{
          width: '100%',
          position: 'relative',
          marginBottom: '25px',
        }}
      >
        {label ? (
          <Typography
            id={id}
            variant="body2"
            align="left"
            color="cumin.main"
            mb={1}
            ml={2}
          >
            {label}
          </Typography>
        ) : null}
        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          {name === 'phone_number' && countryCode ? (
            <Typography
              variant="caption"
              color="garamMasala.main"
              sx={{
                padding: '5px 10px 5px 15px',
                borderRadius: '25px 0 0 25px',
                border: `1px solid ${theme.palette.offWhite.main}`,
                borderRight: 'none',
                backgroundColor: `${theme.palette.turmeric.main}`,
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {getCode}
            </Typography>
          ) : null}
          <Input
            autoComplete={autoComplete || 'off'}
            disableUnderline
            value={
              name === 'phone_number' &&
              (countryCode === 'can' ||
                countryCode === 'usa' ||
                countryCode === 'gbr')
                ? getFormattedNumber
                : value
            }
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            //className={className}
            sx={{
              padding: '5px 25px 5px 10px',
              border: `1px solid ${theme.palette.offWhite.main}`,
              borderLeft: 'none',
              backgroundColor: 'white',
              borderRadius: '0 25px 25px 0',
              outline: 'none',
              color: !value || disabled ? '#a6a6a6' : 'cumin.main',
              fontSize: '16px !important',
              width: '100%',
              height: '50px',
            }}
            type={type}
          />
        </Box>
        {error && (
          <Typography variant="caption" color="red.main" mt={1} ml={2}>
            {error}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

export default MobileInputField;
