import { Box, Input, Typography } from '@mui/material';
import { getDecimalPricePerMeal } from '@utils/helpers';
import theme from 'src/style-system/theme';

const AmountLabelInput = (props: any) => {
  const { name, value, onChange, placeholder, currencySymbol, minimumValue } =
    props;

  return (
    <>
      <Box
        width="100%"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          bgcolor="turmeric.main"
          sx={{
            width: '20%',
            height: '59px',
            borderTopLeftRadius: '30px',
            borderBottomLeftRadius: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
              height: '74px',
              borderTopLeftRadius: '65px',
              borderBottomLeftRadius: '65px',
            },
          }}
        >
          <Typography variant="body1" align="center" color="garamMasala.main">
            {currencySymbol}
          </Typography>
        </Box>
        <Input
          disableUnderline
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          type="number"
          inputProps={{
            min: getDecimalPricePerMeal(minimumValue),
            inputmode: 'numeric',
          }}
          sx={{
            padding: '2px 25px',
            border: `1px solid ${theme.palette.offWhite.main}`,
            backgroundColor: 'white',
            // borderRadius: '25px',
            outline: 0,
            color: 'cumin.main',
            fontSize: '16px !important',
            width: '80%',
            height: '60px',
            borderTopRightRadius: '30px',
            borderBottomRightRadius: '30px',
            [theme.breakpoints.up('md')]: {
              padding: '20px 25px',
              fontSize: '24px',
              borderTopRightRadius: '65px',
              borderBottomRightRadius: '65px',
              height: '75px',
            },

            /* Chrome, Safari, Edge, Opera */
            'input::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
              margin: 0,
            },
            /* Firefox */
            'input[type=number]': {
              '-moz-appearance': 'textfield',
            },
          }}
        />
      </Box>
    </>
  );
};

export default AmountLabelInput;
