import { ChangeEvent, memo, useEffect, useState } from 'react';
import { Box, Typography, Radio } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useReactiveVar } from '@apollo/client';

import { usePlanAndFreqNew } from 'src/http/planAndFreq';
import theme from 'src/style-system/theme';
import { selectFrequencyChoiceVar } from 'store/utils';
import {
  updateSelectedFreq,
  usePersistentplanAndFreq,
} from 'store/planAndFreq';
import { formattedString } from '@utils/formattedString';
import Controls from '@components/Controls';
import { optionsType } from '@utils/types';

import { useStyles } from './useStyles';
import { getDecimalPricePerMeal } from '@utils/helpers';

export enum FREQUENCY_CHOICE {
  SUBSCRIPTION = 1,
  ONE_TIME = 2,
}

const FrequencyAndOneTime = ({ onSelectOneTimeOrder, orderTotalData }: any) => {
  const [freqOptions, setFreqOptions] = useState<optionsType[]>([]);
  const [selectedFreq, setSelectedFreq] = useState<string>('');

  const { orderTotal: orderData } = orderTotalData || {};

  const {
    shippingCost,
    currencySymbol,
    orderTotal,
    nonSidesPrice,
    sidesPrice,
    promoDetails,
  } = orderData || {};

  const { discountAmount } = promoDetails?.isPromoValid
    ? promoDetails
    : { discountAmount: 0 };

  const getSelectedCountryCode = localStorage.getItem('iso_alpha_3_code');
  const selectedCountryCode = getSelectedCountryCode
    ? JSON.parse(getSelectedCountryCode)
    : 'usa';
  const planAndFreq = usePersistentplanAndFreq();

  const { frequencies } = usePlanAndFreqNew(selectedCountryCode);

  const selectedOption = useReactiveVar(selectFrequencyChoiceVar);

  useEffect(() => {
    if (frequencies) {
      if (planAndFreq?.selectedFrequency) {
        setSelectedFreq(planAndFreq?.selectedFrequency);
      } else {
        setSelectedFreq(frequencies[0]);
      }
      const freqOptions = frequencies?.map((freq, index) => {
        return {
          id: index + 1,
          name: formattedString(freq) || '',
          value: freq || '',
        };
      });
      if (freqOptions?.length) {
        setFreqOptions(freqOptions);
      }
    }
  }, [frequencies]);

  const classes = useStyles();

  const handleClick = (optionNumber: number) => {
    selectFrequencyChoiceVar(optionNumber);
    onSelectOneTimeOrder(optionNumber);
    if (optionNumber === FREQUENCY_CHOICE.ONE_TIME) {
      updateSelectedFreq('');
    } else {
      updateSelectedFreq(selectedFreq);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedFreq(value);
    updateSelectedFreq(value);
  };

  return (
    <Box className={classes.mainContainer}>
      <Typography variant="h3" color="cinnamon.main" mb="15px">
        Frequency
      </Typography>
      <Box className={classes.optionWrapper}>
        <Box
          className={classes.freqOption}
          sx={{ borderBottom: `1px solid ${theme.palette.cinnamon.main}` }}
        >
          <Radio
            checked={selectedOption === FREQUENCY_CHOICE.SUBSCRIPTION}
            disabled={selectedOption === FREQUENCY_CHOICE.SUBSCRIPTION}
            sx={{
              padding: 0,
              '&, &.Mui-checked': {
                color: theme.palette.cinnamon.main,
                transform: 'scale(0.8)',
              },
            }}
            id="subscribeAndSave"
            onClick={() => handleClick(FREQUENCY_CHOICE.SUBSCRIPTION)}
          />
          <Box className={classes.optionDesc}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <label htmlFor="subscribeAndSave">
                <Typography
                  variant="body2"
                  color="cinnamon.main"
                  mb="12px"
                  sx={{ cursor: 'pointer' }}
                >
                  Subscribe and Save
                </Typography>
              </label>
              <Box>
                <Typography variant="caption" color="garamMasala.main">
                  {currencySymbol +
                    '' +
                    getDecimalPricePerMeal(
                      (nonSidesPrice || 0) + (sidesPrice || 0) - discountAmount
                    ).toFixed(2)}
                </Typography>
                <Typography
                  variant="caption"
                  color="cinnamon.main"
                  sx={{ textDecoration: 'line-through' }}
                  ml="4px"
                >
                  {currencySymbol +
                    '' +
                    getDecimalPricePerMeal(
                      (nonSidesPrice || 0) +
                        (sidesPrice || 0) -
                        discountAmount +
                        1000
                    ).toFixed(2)}
                </Typography>
              </Box>
            </Box>

            <Typography
              variant="caption"
              color="garamMasala.main"
              className={classes.flexRow}
            >
              {<DoneIcon />}Free shipping
            </Typography>
            <Typography
              variant="caption"
              color="garamMasala.main"
              className={classes.flexRow}
            >
              {<DoneIcon />}Pause or cancel anytime
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '8px 24px',
              }}
              mt="12px"
            >
              <Typography variant="caption" color="garamMasala.main">
                Delivers every
              </Typography>
              <Controls.Select
                name="frequency"
                placeholder="Select Frequency"
                value={selectedFreq}
                options={freqOptions}
                onChange={handleInputChange}
                className={classes.freqOptionSelect}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.freqOption}>
          <Radio
            checked={selectedOption === FREQUENCY_CHOICE.ONE_TIME}
            disabled={selectedOption === FREQUENCY_CHOICE.ONE_TIME}
            sx={{
              padding: 0,
              '&, &.Mui-checked': {
                color: theme.palette.cinnamon.main,
                transform: 'scale(0.8)',
              },
            }}
            id="onceOption"
            onClick={() => handleClick(FREQUENCY_CHOICE.ONE_TIME)}
          />
          <label htmlFor="onceOption">
            <Typography
              variant="body2"
              color="cinnamon.main"
              sx={{ cursor: 'pointer' }}
            >
              Buy Once
            </Typography>
          </label>
          <Typography
            variant="caption"
            align="right"
            color="garamMasala.main"
            sx={{ flex: 1, alignSelf: 'center' }}
          >
            {currencySymbol +
              '' +
              getDecimalPricePerMeal(
                (nonSidesPrice || 0) + (sidesPrice || 0) - discountAmount
              ).toFixed(2) +
              ' + ' +
              currencySymbol +
              10 +
              ' ' +
              'shipping'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(FrequencyAndOneTime);
