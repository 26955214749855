import { getCategorizedProductsType, productType } from './types';

export const DECIMAL_CONVERSION_FACTOR = 100;
export const getDecimalPricePerMeal = (price: number): number => {
  if (!price) {
    return 0;
  }
  return price / DECIMAL_CONVERSION_FACTOR;
};

export const isArrTwoIncludeArrOne = (
  arrOne: (string | number)[],
  arrTwo: (string | number)[]
) => {
  if (!arrOne.length) {
    return true;
  }
  const isEqual = arrOne.every((element) => {
    return Array.isArray(arrTwo) ? arrTwo.includes(element) : false;
  });
  return isEqual;
};

export const isArrTwoIncludeAnyElementOfArrOne = (
  arrOne: (string | number)[],
  arrTwo: (string | number)[]
) => {
  if (!arrOne.length) {
    return true;
  }
  const isInclude = arrOne.filter((element) => {
    return Array.isArray(arrTwo) ? arrTwo.includes(element) : false;
  });

  return isInclude.length ? true : false;
};

export const createCartItemList = ({ productsData, makeActive }: any) => {
  let productFullDataList: { [product_id: number]: productType } = {};
  let productIdQuantityList: any = {};

  productsData?.map((product: any) => {
    if (!productFullDataList[product.id]) {
      productFullDataList[product.id] = makeActive
        ? { ...product, isActive: true }
        : product;
      productIdQuantityList[product.id] = product.quantity;
    }
  });

  return { productFullDataList, productIdQuantityList };
};

export const calculateMealCounts = ({
  productFlatList,
  cartItems,
  categories,
}: any) => {
  if (
    !Object.keys(cartItems)?.length &&
    !Object.keys(productFlatList)?.length
  ) {
    return 0;
  }
  const cartItemsValueArr = Object.values(cartItems);
  const cartItemsKeysArr = Object.keys(cartItems);
  let mealCount: number = 0;
  // TODO: lets decide either unitperside is should not be null in case of side
  //const sidesProductsIdArr = categories?.find(({ category_name }: any) => category_name === 'sides')?.products?.map((product: any) => product.id);

  if (cartItemsValueArr.length) {
    cartItemsKeysArr?.map((key: any) => {
      // exclude side from meal count
      // if (productFlatList[key]?.isActive && !sidesProductsIdArr.includes(+key)) {
      //   mealCount += +cartItems[key];
      // }
      if (
        productFlatList[key]?.isActive &&
        !productFlatList[key]?.unitsPerSide
      ) {
        mealCount += +cartItems[key];
      }
    });
  }

  return mealCount;
};

export const getSidesRequired = ({ productFlatList, cartItems }: any) => {
  if (
    !Object.keys(cartItems)?.length ||
    !Object.keys(productFlatList)?.length
  ) {
    return {};
  }
  const sidesValuesObj: any = {};
  const cartItemsKeysArr = Object.keys(cartItems);
  if (cartItemsKeysArr.length) {
    cartItemsKeysArr?.map((key: any) => {
      const { isActive, sides } = productFlatList[key] || {};

      if (isActive && sides?.length) {
        sides.forEach((sideItem: any) => {
          const { recommendedUnitsPerSide, side } = sideItem || {};
          const { id } = side || {};
          if (sidesValuesObj[id]) {
            sidesValuesObj[id] =
              sidesValuesObj[id] + recommendedUnitsPerSide * cartItems[key];
          } else {
            sidesValuesObj[id] = recommendedUnitsPerSide * cartItems[key];
          }
        });
      }
    });
  }
  return sidesValuesObj;
};

export const getSidesRecomendation = ({ requiredSides, categories }: any) => {
  const sidesValuesObj: any = {};

  const sidesProduct =
    categories?.find(({ category_name }: any) => category_name === 'sides')
      ?.products || [];

  sidesProduct?.forEach((product: any) => {
    const { unitsPerSide, isActive, id } = product || {};
    if (unitsPerSide && isActive && requiredSides[id]) {
      const getTotalUnit = Math.floor(requiredSides[id] / unitsPerSide);
      const remainder = requiredSides[id] % unitsPerSide;
      sidesValuesObj[id] = remainder ? getTotalUnit + 1 : getTotalUnit;
    } else {
      sidesValuesObj[id] = 0;
    }
  });
  return sidesValuesObj;
};

export const calculateDishCount = (products: any) => {
  if (!products || !products?.length) {
    return 0;
  }

  return products?.reduce(
    (quantity: any, product: any) => (quantity = quantity + product.quantity),
    0
  );
};

export const isSideAdded = ({ productFlatList, cartItems }: any) => {
  if (
    !Object.keys(cartItems)?.length ||
    !Object.keys(productFlatList)?.length
  ) {
    return false;
  }

  const cartItemsKeysArr = Object.keys(cartItems);
  for (const key of cartItemsKeysArr) {
    const { unitsPerSide } = productFlatList[key] || {};

    if (unitsPerSide) {
      return true;
    }
  }

  return false;
};

export const createProductIdQuantityObj = (products: any) => {
  if (!products || !products?.length) {
    return {};
  }
  const productIdQuantity: any = {};
  products.forEach((orderProduct: any) => {
    const { product, quantity } = orderProduct;
    if (product.isActive) {
      productIdQuantity[product.id] = quantity;
    }
  });
  return productIdQuantity;
};

export const getPriceAfterDiscount = ({
  price,
  amountType = 'PERCENTAGE',
  amount = 15,
}: {
  price: number;
  amountType?: string;
  amount?: number;
}) => {
  let discountTotal = price;
  if (amountType === 'PERCENTAGE') {
    discountTotal = price - price * (amount / 100);
  } else if (amountType === 'ABSOLUTE') {
    discountTotal = price;
  } else if (amountType === 'FREE_MEALS' || amountType === 'FREE_GIFTS') {
    discountTotal = price;
  }

  return getDecimalPricePerMeal(discountTotal);
};

export const addBestSellerProduct = ({
  bestSellerCategory,
  selectedPlan,
  count,
  productIdQuantityList,
}: {
  bestSellerCategory: getCategorizedProductsType | undefined;
  selectedPlan: number;
  count: number;
  productIdQuantityList: any;
}): any => {
  bestSellerCategory?.products?.forEach((product) => {
    if (count === selectedPlan) {
      return productIdQuantityList;
    } else {
      if (productIdQuantityList[product.id]) {
        productIdQuantityList[product.id] += 1;
      } else {
        productIdQuantityList[product.id] = 1;
      }
      count++;
    }
  });

  if (count === selectedPlan) {
    return productIdQuantityList;
  } else {
    return addBestSellerProduct({
      bestSellerCategory,
      selectedPlan: selectedPlan,
      count,
      productIdQuantityList,
    });
  }
};

export const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
