import Link from 'next/link';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../src/style-system/theme';
import createEmotionCache from '../src/createEmotionCache';
import '../src/style-system/global.css';
import { ApolloProvider } from '@apollo/client';

import { useApollo } from '../graphql/apollo';
import ClientOnly from '@components/ClientOnly';
import { useRouter } from 'next/router';
import Snackbar from '@components/Snackbar';
import Script from 'next/script';
import { BASE_URL } from '@utils/data';

// prismic
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreview } from '@prismicio/next';
import { linkResolver, repositoryName, client } from '../prismicio';
import { useEffect } from 'react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();

  const getMyElevarUserId = async () => {
    // First, try to get the Shopify user ID from the cookie
    const shopifyCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_shopify_y='));
    if (shopifyCookie) {
      return shopifyCookie.split('=')[1];
    }

    // If no Shopify cookie, fall back to the local user ID
    return Promise.resolve('user-7e1409gq7');
  };

  //Canonical URL is a URL which tells search engines the original source of a particular page.
  const canonicalUrl = (
    BASE_URL + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0];

  useEffect(() => {
    // @ts-ignore
    window.ElevarUserIdFn = async () => {
      const userId = await getMyElevarUserId();
      return userId;
    };
    window.addEventListener('unload', function () {});
    window.addEventListener('beforeunload', function () {});
  }, []);

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <PrismicProvider
        client={client}
        linkResolver={linkResolver}
        internalLinkComponent={({ href, ...props }) => (
          <Link href={href}>
            <a {...props} />
          </Link>
        )}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <ApolloProvider client={apolloClient}>
            <CacheProvider value={emotionCache}>
              <Head>
                <meta
                  name="viewport"
                  content="initial-scale=1, width=device-width"
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg"
                  color="#d46a19"
                />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#ffffff"></meta>
              </Head>

              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <ClientOnly>
                  <Layout Component={Component} pageProps={pageProps} />
                </ClientOnly>
              </ThemeProvider>
            </CacheProvider>
            <Snackbar />
          </ApolloProvider>
          <Script
            id="elevar-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (async () => {
                const settings = { proxyPath: "/elevar" };
                const config = (await import("/elevar/static/configs/f56348c34a5805ee33fdeda751632e2f43e6eb2d/config.js")).default;
                const scriptUrl = settings.proxyPath
                  ? settings.proxyPath + config.script_src_custom_pages_proxied
                  : config.script_src_custom_pages;

                if (scriptUrl) {
                  const { handler } = await import(scriptUrl);
                  await handler(config, settings);
                }
              })()
            `,
            }}
          />
          <Script
            id="gtm-load"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM}');`,
            }}
          />

          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(){
              const d=document,s=d.createElement("script");
              s.type="module";
              s.src="https://w.behold.so/widget.js";
              d.head.append(s);
              })();`,
            }}
          />
          {/* <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      /> */}

          {/* <Script
              strategy="lazyOnload"
              src="//code.tidio.co/bxxtkaes2hrjozpu2ou5gg91l0cwnemf.js"
            /> */}

          <Script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBTvAIZCBVQW5km8mDp3iO5AUYby1v8Mlw&libraries=places" />

          {/*Klaviyo Script */}
          <Script
            type="application/javascript"
            strategy="afterInteractive"
            src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO}`}
          />

          <Script
            id="gorgias-chat-widget-install-v3"
            src="https://config.gorgias.chat/bundle-loader/01GZC4T5CP2V6F3FG2A89TR3DM"
            strategy="afterInteractive"
          />

          {/*Klaviyo signup form Script */}
          {/* <Script
              async
              type="text/javascript"
              src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO}`}
            /> */}

          {/* <Script
            src="https://www.dwin1.com/38140.js"
            type="text/javascript"
            defer
          />

          <Script
            id="fc1"
            src="https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js"
          />

          <Script
            id="fc2"
            dangerouslySetInnerHTML={{
              __html: `var preChatTemplate = {
              //Form header color and Submit button color.
              mainbgColor: '#D46A19',
              //Form Header Text and Submit button text color.
              maintxColor: '#fff',
              //Chat Form Title
              heading: 'The Cumin Club',
              //Chat form Welcome Message
              textBanner: 'Hello! Thank you for reaching out. Kindly share your email ID to assist you further.',
              //Submit Button Label.
              SubmitLabel: 'Start Chat',
              //Fields List - Maximum is 5
              //All the values are mandatory and the script will not work if not available.
              fields : {
                field1 : {
                  //Type for Email - Do Not Change
                  type: "email",
                  //Label for Field Email, can be in any language
                  label: "Email",
                  //Default - Field ID for Email - Do Not Change
                  fieldId: "email",
                  //Required "yes" or "no"
                  required: "yes",
                  //Error text to be displayed
                  error: "Please Enter a valid Email"
                },
              }
            };
            window.fcSettings = {
              token: "bd25b2eb-45d2-4f2e-b1e4-093a90ae003f",
              host: "https://thecuminclub.freshchat.com",
              config: {
                cssNames: {
                  //The below element is mandatory. Please add any custom class or leave the default.
                  widget: 'custom_fc_frame',
                  //The below element is mandatory. Please add any custom class or leave the default.
                  expanded: 'custom_fc_expanded'
                }
              },
              onInit: function() {
                console.log('widget init');
                fcPreChatform.fcWidgetInit(preChatTemplate);
              }
            };`,
            }}
          />
          
          */}

          {/* Latest fc widget */}
          {/* <Script
            id="fc3"
            src="https://wchat.freshchat.com/js/widget.js"
            strategy="afterInteractive"
          />
          <Script id="fd" strategy="afterInteractive">
            {`function initFreshChat() {
              window.fcWidget.init({
                  token: "bd25b2eb-45d2-4f2e-b1e4-093a90ae003f",
            host: "https://thecuminclub.freshchat.com"
              });
            }
            function initialize(i,t){var e;i.getElementById(t)?
            initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
            e.src="https://thecuminclub.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
            function initiateCall(){initialize(document,"Freshchat-js-sdk")}
            window.addEventListener?window.addEventListener("load",initiateCall,!1):
            window.attachEvent("load",initiateCall,!1);`}
          </Script> */}

          {/* End */}

          {/* Freshchat bot widget code that is soon to be deprecated */}
          {/* <Script id="fc4" strategy="lazyOnload">
            {` (function (d, w, c) { if(!d.getElementById("spd-busns-spt")) { var n = d.getElementsByTagName('script')[0], s = d.createElement('script'); var loaded = false; s.id = "spd-busns-spt"; s.async = "async"; s.setAttribute("data-self-init", "false"); s.setAttribute("data-init-type", "opt"); s.src = 'https://cdn.in-freshbots.ai/assets/share/js/freshbots.min.js'; s.setAttribute("data-client", "2c53e3c860cf915b93b60a35252a151543f154e7"); s.setAttribute("data-bot-hash", "a33babf331b77b16896f8b623a470ff285c8a24e"); s.setAttribute("data-env", "prod"); s.setAttribute("data-region", "in"); if (c) { s.onreadystatechange = s.onload = function () { if (!loaded) { c(); } loaded = true; }; } n.parentNode.insertBefore(s, n); } }) (document, window, function () { Freshbots.initiateWidget({ autoInitChat: false, getClientParams: function () { return ; } }, function(successResponse) { }, function(errorResponse) { }); }); `}
          </Script> */}

          {/* <Script id="fc5" strategy="lazyOnload">
            {`function initFreshChat() {
    window.fcWidget.init({
      	 token: "bd25b2eb-45d2-4f2e-b1e4-093a90ae003f",
	 host: "https://thecuminclub.freshchat.com"
    });
  }
  function initialize(i,t){var e;i.getElementById(t)?
  initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
  e.src="https://thecuminclub.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
  function initiateCall(){initialize(document,"Freshchat-js-sdk")}
  window.addEventListener?window.addEventListener("load",initiateCall,!1):
  window.attachEvent("load",initiateCall,!1);`}
          </Script> */}

          {/* <Script
            id="show-banner"
            strategy="beforeInteractive"
            dangerouslySetInnerHTML={{
              __html: `function initFreshChat() {
              window.fcWidget.init({
                   token: "bd25b2eb-45d2-4f2e-b1e4-093a90ae003f",
             host: "https://thecuminclub.freshchat.com"
              });
            }
            function initialize(i,t){var e;i.getElementById(t)?
            initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
            e.src="https://thecuminclub.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
            function initiateCall(){initialize(document,"Freshchat-js-sdk")}
            window.addEventListener?window.addEventListener("load",initiateCall,!1):
            window.attachEvent("load",initiateCall,!1);`,
            }}
          /> */}
        </PrismicPreview>
      </PrismicProvider>
    </>
  );
};

const Layout = ({ Component, pageProps }: any) => {
  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />, pageProps);
  } else {
    return <Component {...pageProps} />;
  }
};
export default MyApp;
