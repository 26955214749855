import React from 'react';
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  Typography,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import theme from 'src/style-system/theme';
import { height } from '@mui/system';

const Select = (props: any) => {
  const {
    id,
    name,
    label,
    value,
    error = null,
    onChange,
    onBlur = () => {},
    options,
    placeholder,
    disabled = false,
    className,
    muiSelectclasses,
    isTelCode = false,
    defaultValue = 'none',
    textStyle,
  } = props;

  const CustomSvgIcon = () => {
    return (
      <ArrowDropDownIcon
        fontSize="medium"
        sx={{ color: 'garamMasala.dark', display: disabled ? 'none' : 'block' }}
      />
    );
  };

  return (
    <>
      <FormControl
        variant="standard"
        sx={{ width: '100%', position: 'relative', marginBottom: '25px' }}
        className={className}
      >
        {label ? (
          <Typography
            id={id}
            variant="body2"
            align="left"
            color="cumin.main"
            mb={1}
            ml={2}
          >
            {label}
          </Typography>
        ) : null}
        <MuiSelect
          disableUnderline
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          className={muiSelectclasses}
          //IconComponent={CustomSvgIcon}
          sx={{
            padding: '8px 5px 5px 25px',
            border: `1px solid ${theme.palette.offWhite.main}`,
            backgroundColor: 'white',
            borderRadius: '25px',
            outline: 'none',
            color: value === 'none' ? 'lightGray.main' : 'cumin.main',
            fontSize: '16px !important',
            width: '100%',
            height: '50px',
            '& .MuiSvgIcon-root': {
              fontSize: isTelCode ? '25px' : '35px',
              color: 'garamMasala.dark',
            },
          }}
          disabled={disabled}
        >
          <MenuItem disabled value="none" sx={{ display: 'none' }}>
            {placeholder}
          </MenuItem>
          {options?.map((item: any) => (
            <MenuItem key={item.id} value={item.value}>
              <Typography
                variant="body2"
                align="left"
                color="cumin.main"
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                className={textStyle}
              >
                {item.name}
              </Typography>
            </MenuItem>
          ))}
        </MuiSelect>
        {error && (
          <Typography variant="caption" color="red.main" mt={1} ml={2}>
            {error}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

export default Select;
