export const formattedString = (word?: string) => {
  if (!word) return null;
  if (word && word.includes('_')) {
    const splitWord = word
      .split('_')
      ?.map(
        (word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
      );      
    const joinWord = splitWord.join('-');
    return joinWord;
  }
  const formatWord =
    word && word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();

  return formatWord;
};
