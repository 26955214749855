import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

// This store is used to store selected freq and plan data

export interface planAndFreqState {
  selectedPlanId: number;
  selectedPlan: number;
  selectedFrequency: string;
}

export const planAndFreqVar = makeVar<planAndFreqState>({
  selectedPlanId: 0,
  selectedPlan: 0,
  selectedFrequency: '',
});

export const updateSelectedFreq = (freq: string) => {
  planAndFreqVar({
    ...planAndFreqVar(),
    selectedFrequency: freq,
  });

  localStorage.setItem('planAndFreq', JSON.stringify(planAndFreqVar()));
};

export const updateSelectedPlan = (plan: number, planId: number) => {
  planAndFreqVar({
    ...planAndFreqVar(),
    selectedPlan: plan,
    selectedPlanId: planId,
  });

  localStorage.setItem('planAndFreq', JSON.stringify(planAndFreqVar()));
};

export const usePersistentplanAndFreq = () => {
  const planAndFreqState = useReactiveVar(planAndFreqVar);
  useEffect(() => {
    const planAndFreq = localStorage.getItem('planAndFreq');
    if (planAndFreq) {
      planAndFreqVar(JSON.parse(planAndFreq));
    }
  }, []);
  return planAndFreqState;
};
