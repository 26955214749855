import { makeVar } from '@apollo/client';
import { FREQUENCY_CHOICE } from '@containers/CombinedOrderStepThreeAndFour/FrequencyAndOneTime';
import { AppRoutes } from '@utils/appRoutes';

export interface IUtislState {
  redirectAfterSigninUrl: string | null;
  isDirectRoutingValid: boolean;
}

export type TbackUrlVar = {
  url: string;
  step: number;
} | null;

export const utilsStateVar = makeVar<IUtislState>({
  redirectAfterSigninUrl: null,
  isDirectRoutingValid: false,
});

export const emailStateVar = makeVar<string>('');
export const subscriptionIdStateVar = makeVar<string>('');
export const promotionalUpdatesVar = makeVar<boolean>(false);
export const orderTotalStateVar = makeVar<string>('');

export const utmQueryVar = makeVar<any>(null);

export const setEmailAddress = (email: string) => {
  emailStateVar(email);
};

export const setRedirectAfterSigninUrl = (url: string) => {
  utilsStateVar({
    ...utilsStateVar(),
    redirectAfterSigninUrl: url,
  });
};

export const setDirectRoutingValidation = (isValid: boolean) => {
  utilsStateVar({
    ...utilsStateVar(),
    isDirectRoutingValid: isValid,
  });
};

export const snackbarInfo = makeVar<any>(null);

export const selectAddressIdVar = makeVar<number | null>(null);

export const isBackToGiftVar = makeVar<string>('');

export const backUrlVar = makeVar<TbackUrlVar>(null);

export const isFreqVar = makeVar<boolean>(false);

export const isComeFromRedirectVar = makeVar<boolean>(false);

export const selectFrequencyChoiceVar = makeVar<number>(
  FREQUENCY_CHOICE.SUBSCRIPTION
);

//for sending exp to variant depend upon this
export const shouldSendAbTestToAnalytics = makeVar<boolean>(false);

//for sending new_plan_ab_test
export const homePageAbTestVar = makeVar<boolean>(false);
export const stepTwoplanAbTestVar = makeVar<boolean>(false);
export const stepThreeplanAbTestVar = makeVar<boolean>(false);
export const stepFourplanAbTestVar = makeVar<boolean>(false);
export const stepFiveplanAbTestVar = makeVar<boolean>(false);
export const successplanAbTestVar = makeVar<boolean>(false);

// plan ab test name
export const planAbTestNameVar = makeVar<string>('');

// is AB test fired
export const isABTestToFiredVar = makeVar<boolean>(false);

// isNewOrderFlow
export const isNewOrderFlowVar = makeVar<boolean>(false);

// for source url
export const sourceUrlVar = makeVar<string>('');

// for indian redirect modal open one's per session
export const redirectIndiaModalOnesOpenVar = makeVar<boolean>(false);

export const countryCodeForEventVar = makeVar<string>('');
