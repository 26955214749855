import { makeStyles } from '@mui/styles';
import theme from 'src/style-system/theme';

export const useStyles = makeStyles({
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },

  mainContainer: {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '20px',

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      marginBottom: '40px',
    },
  },

  optionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.cinnamon.main}`,
    borderRadius: '12px',
  },

  freqOption: {
    padding: '16px 12px',
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '12px',
  },

  optionDesc: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  freqOptionSelect: {
    maxWidth: '220px',
    marginBottom: '0px',
  },
});
