import { Box } from '@mui/material';

import theme from 'src/style-system/theme';
import TCCLogo from '@components/TCCLogo';

import { useStyles } from './useStyles';

interface IPageLoader {
  isTransparent?: boolean;
  height?: string;
  padding?: string;
  backGroundColor?: string;
  isFixed?: boolean;
}

const PageLoader = ({
  isTransparent,
  height = '100vh',
  padding = '0px',
  backGroundColor = theme.palette.whitePepper.main,
  isFixed,
}: IPageLoader) => {
  const classes = useStyles();

  let moreStyles = {};
  if (isFixed) {
    moreStyles = {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1400,
      width: '100%',
    };
  }

  return (
    <Box
      className={classes.mainContainer}
      sx={{
        backgroundColor: backGroundColor,
        opacity: isTransparent ? 0.75 : 1,
        height: height,
        padding: padding,
        ...moreStyles,
      }}
    >
      <div className={classes.logo}>
        <TCCLogo />
      </div>
    </Box>
  );
};

export default PageLoader;
