import { ApolloError, makeVar, useQuery, useReactiveVar } from '@apollo/client';
import { TfrequenciesV2, plansType } from '@utils/types';
import { gql } from 'graphql-tag';
import {
  fetchPlanAndFreqVar,
  updateFetchFreq,
  updateFetchPlan,
} from 'store/planAndFreqnew';

export const GET_SIDE = gql`
  query GetPlanAndFrequencies($input: GetPlansAndFrequenciesInput!) {
    getPlansAndFrequencies(input: $input) {
      plans {
        id
        plan_name
        price
        currency_symbol
      }
    }
  }
`;

export const GET_PLAN_FREQ = gql`
  query GetPlanAndFrequencies($input: GetPlansAndFrequenciesInput!) {
    getPlansAndFrequencies(input: $input) {
      plans {
        id
        plan_name
        count_of_dishes
        price
        badge
        currency_symbol
      }
      frequencies
      frequenciesV2 {
        id
        value
      }
    }
  }
`;
type TPlanAndFreqNew = {
  data:
    | {
        getPlansAndFrequencies: {
          frequencies: string[];
          plans: plansType[];
          frequenciesV2: TfrequenciesV2[];
        };
      }
    | undefined;
  loading: boolean;
  error?: ApolloError | undefined;
};

export const fetchCountrycodeVar = makeVar<string>('');

export const usePlanAndFreqNew = (country_code: string) => {
  const getSelectedCountryCode = useReactiveVar(fetchCountrycodeVar);
  const getFetchPlanAndFreq = useReactiveVar(fetchPlanAndFreqVar);
  const isPlan = getFetchPlanAndFreq.plans?.length ? true : false;
  const isFreq = getFetchPlanAndFreq.frequencies?.length ? true : false;

  const { data, loading, error }: TPlanAndFreqNew = useQuery(GET_PLAN_FREQ, {
    variables: {
      input: {
        countryCode: country_code,
        filters: {
          type: 'MEAL',
        },
      },
    },
    fetchPolicy: 'network-only',
    skip:
      !country_code ||
      (getSelectedCountryCode === country_code && (isPlan || isFreq)),
  });

  if (data) {
    fetchCountrycodeVar(country_code);
    updateFetchFreq(data?.getPlansAndFrequencies?.frequencies);
    updateFetchPlan(data?.getPlansAndFrequencies?.plans);
  }

  if (getSelectedCountryCode === country_code && (isPlan || isFreq)) {
    return {
      plans: getFetchPlanAndFreq.plans,
      frequencies: getFetchPlanAndFreq.frequencies,
      planFreqLoading: false,
      planFreqError: null,
    };
  } else {
    return {
      plans: data?.getPlansAndFrequencies?.plans,
      frequencies: data?.getPlansAndFrequencies?.frequencies,
      planFreqLoading: loading,
      planFreqError: error,
    };
  }
};
