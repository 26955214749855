import { linkType } from './types';
import { AppRoutes } from './appRoutes';

import Instagram from '@public/assets/svg/Instagram.svg';
import Facebook from '@public/assets/svg/Facebook.svg';
import Tiktok from '@public/assets/svg/Tiktok.svg';

const headerList: linkType[] = [
  { id: 1, value: 'Build Your Box', link: AppRoutes.ORDER_STEP_ONE },
  { id: 2, value: 'Account', link: AppRoutes.ORDERS },
  { id: 3, value: 'Login', link: AppRoutes.LOGIN },
  { id: 4, value: 'Gifts', link: AppRoutes.GIFT },
  { id: 5, value: 'Our Story', link: AppRoutes.OUR_STORY },
  { id: 6, value: 'How It Works', link: AppRoutes.HOW_IT_WORK },
  { id: 7, value: 'FAQs', link: AppRoutes.FAQS },
];

export const newHeaderList: linkType[] = [
  { id: 1, value: 'Our Story', link: AppRoutes.OUR_STORY },
  { id: 2, value: 'Gifts', link: AppRoutes.GIFT },
  { id: 3, value: 'FAQs', link: AppRoutes.FAQS },
];

const baseHeaderList: linkType[] = [
  { id: 1, value: 'Blog', link: AppRoutes.BLOG },
  { id: 2, value: 'Reviews', link: AppRoutes.REVIEWS },
  { id: 3, value: 'Press Kit', link: AppRoutes.PRESS_KIT },
  { id: 4, value: 'Help', link: AppRoutes.FAQS },
  { id: 5, value: 'Logout', link: '' },
];

export const socialMedia = [
  { id: 1, Icon: Instagram, href: 'https://www.instagram.com/the_cumin_club/' },
  { id: 2, Icon: Facebook, href: 'https://www.facebook.com/thecuminclubusa/' },
  { id: 3, Icon: Tiktok, href: 'https://www.tiktok.com/@the_cumin_club' },
];

const footerList: linkType[] = [
  { id: 1, value: 'Meal Plans', link: AppRoutes.ORDER_STEP_ONE },
  { id: 5, value: 'How It Works', link: AppRoutes.HOW_IT_WORK },
  { id: 2, value: 'Our Story', link: AppRoutes.OUR_STORY },
  { id: 4, value: 'Blog', link: AppRoutes.BLOG },
  { id: 6, value: 'Reviews', link: AppRoutes.REVIEWS },
  { id: 7, value: 'Gifts', link: AppRoutes.GIFT },
  { id: 8, value: 'Help', link: AppRoutes.FAQS },
  { id: 9, value: 'Press Kit', link: AppRoutes.PRESS_KIT },
];

const baseFooterList: linkType[] = [
  { id: 1, value: 'Terms', link: AppRoutes.TERMS },
  { id: 2, value: 'Privacy', link: AppRoutes.PRIVACY },
  { id: 3, value: 'Refunds', link: AppRoutes.REFUNDS },
  { id: 4, value: 'Accessibility Statement', link: AppRoutes.ACCESSIBILITY },
];

const orderFlowfooterContent: linkType[] = [
  { id: 1, value: 'Terms & Conditions', link: AppRoutes.TERMS },
  { id: 2, value: 'Privacy', link: AppRoutes.PRIVACY },
  { id: 3, value: 'Refunds', link: AppRoutes.REFUNDS },
  { id: 4, value: 'Accessibility Statement', link: AppRoutes.ACCESSIBILITY },
];

export enum NewFrequencyVariants {
  BI_MONTHLY = 'BI_MONTHLY',
  TRI_MONTHLY = 'TRI_MONTHLY',
}

export const planAbcTestNameObj: any = {
  0: 'A',
  1: 'B',
  2: 'C',
};

export const AFFILIATE_PROGRAM_LINK =
  'https://www.shareasale.com/shareasale.cfm?merchantID=132985';

const animationduration = 5000;

export const NEW_ORDER_FLOW = 'new-order-flow';

export const COOKIE_NAME = 'ab-page-variant';

export const GIFT_CODE = 'GIFT15';

export const AUTO_PROMO_CODE = 'WELCOME15';

export const NOT_SERVICABLE_ERROR =
  'We are no longer offering our services in your country. We will let you know as soon as we resume services. Meanwhile you can visit www.thecuminclub.com to view countries we currently deliver to. Sorry for the inconvenience caused.';

export const AUTO_DETECT_COUNTRY_URL =
  'https://tcc-cd.saifalfalah.workers.dev/';

export const BASE_URL = 'https://www.thecuminclub.com';

export const INDIA_BASE_URL = 'https://india.thecuminclub.com/';

// VIDEO URL

export const OUR_STORY_PAGE_VIDEO_URL =
  'https://d33g0mhyk4474p.cloudfront.net/the-cumin-club-founder-video-desktop.mp4';

export const OUR_STORY_PAGE_VIDEO_URL_MOBILE =
  ' https://d33g0mhyk4474p.cloudfront.net/the-cumin-club-founder-video-mobile.mp4';

export const HOME_PAGE_VIDEO_URL =
  'https://d222i9ppxs2fqe.cloudfront.net/cumin_club_flavor_original.mp4';

export const HOME_PAGE_VIDEO_URL_MOBILE =
  'https://d222i9ppxs2fqe.cloudfront.net/HEADER_TCC_MOBILE.mp4';

//background image

export const HOME_PAGE_BG_IMAGE_URL_DESKTOP =
  'https://d222i9ppxs2fqe.cloudfront.net/home_page_dt_bg_img.jpg';

export const HOME_PAGE_BG_IMAGE_URL_DESKTOP_BLUR =
  'https://d222i9ppxs2fqe.cloudfront.net/homepage_dt_bg_blur.jpg';

export const HOME_PAGE_BG_IMAGE_URL_TABLET =
  'https://d222i9ppxs2fqe.cloudfront.net/home_page_tb_bg_img.jpg';

export const HOME_PAGE_BG_IMAGE_URL_MINI_TABLET =
  'https://d222i9ppxs2fqe.cloudfront.net/home_page_mini_tb_bg_img+.jpg';

export const HOME_PAGE_BG_IMAGE_URL_TABLET_BLUR =
  'https://d222i9ppxs2fqe.cloudfront.net/home_page_tb_bg_img.jpg';

export const HOME_PAGE_BG_IMAGE_URL_MOBILE =
  'https://d222i9ppxs2fqe.cloudfront.net/home_page_mb_bg_img.jpg';

export const HOME_PAGE_BG_IMAGE_URL_MOBILE_BLUR =
  'https://d222i9ppxs2fqe.cloudfront.net/home_page_mb_bg_img.jpg';

export const HOW_ITS_WORK_BG_IMAGE_URL_MOBILE =
  'https://d222i9ppxs2fqe.cloudfront.net/how_Its_work_bg_img_dt.png';

export const HOW_ITS_WORK_BG_IMAGE_URL_DESKTOP =
  'https://d222i9ppxs2fqe.cloudfront.net/how_Its_work_bg_img_mb.png';

export const LOGIN_PAGE_BG_IMG_URl =
  'https://d222i9ppxs2fqe.cloudfront.net/login_bg_img.jpg';

export const GIFT_PAGE_BG_IMG_URL_MOBILE =
  'https://d222i9ppxs2fqe.cloudfront.net/gift_mb_bg_img.jpg';

export const GIFT_PAGE_BG_IMG_URL_TABLET =
  'https://d222i9ppxs2fqe.cloudfront.net/gift_tb_bg_img.jpg';

export const OUR_STORY_PAGE_POSTER_IMAGE_URL =
  'https://d33g0mhyk4474p.cloudfront.net/the-cumin-club-founder-video-thumbnail.jpg';

export const BLOG_BG_IMAGE_URL_DESKTOP =
  'https://d222i9ppxs2fqe.cloudfront.net/blog_bg_d_img.png';

export const BLOG_BG_IMAGE_URL_MOBILE =
  'https://d222i9ppxs2fqe.cloudfront.net/blog_bg_m_img.png';

export const EXIT_INTENT_BG_IMG =
  'https://d222i9ppxs2fqe.cloudfront.net/exitIntent_bg_img.jpg';

export const BLACK_POSTER_IMAGE_VIDEO =
  'https://d222i9ppxs2fqe.cloudfront.net/black_poster_image_video.png';

export const PANTRY_MODAL_BG_IMAGE =
  'https://d222i9ppxs2fqe.cloudfront.net/step_five_modal_bg_img.png';

export const SIGN_UP_BG_IMAGE_URL =
  'https://d222i9ppxs2fqe.cloudfront.net/sign_up_bg_img.jpg';

// IMAGE URL

export const STEP_ONE_MEAL_GIF =
  'https://d222i9ppxs2fqe.cloudfront.net/STEP_ONE_MEAL.gif';

export const STEP_ONE_MEAL_GIF_FALLBACK =
  'https://d222i9ppxs2fqe.cloudfront.net/STEP_ONE_MEAL_FALLBACK.jpg';

export const STEP_ONE_SIDE_IMG =
  'https://d222i9ppxs2fqe.cloudfront.net/order_step_1_hero_img.png';

export const GIFT_CARD =
  'https://d222i9ppxs2fqe.cloudfront.net/tcc-gift-card.png';

export const FILLED_GIFT_CARD =
  'https://d222i9ppxs2fqe.cloudfront.net/giftcardstandard.png';

export const GIFT_BOX =
  'https://d222i9ppxs2fqe.cloudfront.net/theCuminClub-Gift-Box.png';

export const GIFT_BOX_STRAIGHT =
  'https://d222i9ppxs2fqe.cloudfront.net/theCuminClubGift-Box-Straight.png';

export const DIWALI_GIFT_BOX =
  'https://d222i9ppxs2fqe.cloudfront.net/the-cumin-club-diwali-2023.jpg';

export const DIWALI_PARTY_BOX =
  'https://d222i9ppxs2fqe.cloudfront.net/diwali_party_box.png';

export const GROUPING_BAG =
  'https://d222i9ppxs2fqe.cloudfront.net/groupingBag_opt.png';
export const VEN_PONGAL =
  'https://d222i9ppxs2fqe.cloudfront.net/ven-pongal_opt.png';
export const CHOP_CHEF =
  'https://d222i9ppxs2fqe.cloudfront.net/chopChef_opt.gif';
export const THREE_D_PHONE =
  'https://d222i9ppxs2fqe.cloudfront.net/threeDPhone_opt.png';
export const MILIJULI = 'https://d222i9ppxs2fqe.cloudfront.net/miliJuli.png';
export const GROUPING_BAG_BALANCED =
  'https://d222i9ppxs2fqe.cloudfront.net/groupingBagBalanced.png';
export const GINGER_CHILLI =
  'https://d222i9ppxs2fqe.cloudfront.net/gingerChilli.png';

export const PAV_BHAJI_PACKET =
  'https://d222i9ppxs2fqe.cloudfront.net/pav_bhaji-packet.png';

export const CHEF_ASSET = 'https://d222i9ppxs2fqe.cloudfront.net/head_chef.png';

export const AVS_LOGO = 'https://d222i9ppxs2fqe.cloudfront.net/AVS_logo.png';
export const THE_BETTER_INDIA_LOGO =
  'https://d222i9ppxs2fqe.cloudfront.net/the_better_india_logo.png';

export const DELIVERY_RANK_LOGO =
  'https://d222i9ppxs2fqe.cloudfront.net/delivery_rank_logo.png';
export const BROWN_GIRL_MAGAZINE_LOGO =
  'https://d222i9ppxs2fqe.cloudfront.net/brown_girl_magazine_logo.png';
export const YOUR_STORY_LOGO =
  'https://d222i9ppxs2fqe.cloudfront.net/your_story_logo.png';

export const PANEER_BUTTER_MASALA_WITH_TAG =
  'https://d222i9ppxs2fqe.cloudfront.net/d_Paneer_Butter_Masala.png';
export const AL00_POSTO_WITH_TAG =
  'https://d222i9ppxs2fqe.cloudfront.net/d_Aloo_Posto.png';
export const PAV_BHAJI_WITH_TAG =
  'https://d222i9ppxs2fqe.cloudfront.net/d_Pav_Bhaji.png';
export const VEN_PONGAL_WITH_TAG =
  'https://d222i9ppxs2fqe.cloudfront.net/d_Ven_Pongal.png';

export const HYDERABADI_BIRYANI =
  'https://d222i9ppxs2fqe.cloudfront.net/hyderabadiBiryani.png';
export const PANEER_BHURJI =
  'https://d222i9ppxs2fqe.cloudfront.net/paneer_bhurji.png';
export const MASALA_BHINDI =
  'https://d222i9ppxs2fqe.cloudfront.net/masala_bhindi.png';
export const METHI_MATAR_MALAI =
  'https://d222i9ppxs2fqe.cloudfront.net/methi_matar_malai.png';
export const MALABAR_VEG_KURMA =
  'https://d222i9ppxs2fqe.cloudfront.net/malabar_veg_kurma.png';

export const RIGHT_INCLINED_LAMP =
  'https://d222i9ppxs2fqe.cloudfront.net/right_inclined_lamp.png';
export const LEFT_INCLINED_LAMP =
  'https://d222i9ppxs2fqe.cloudfront.net/left_inclined_lamp.png';

export const NEW_GIFT_BOX_STRAIGHT =
  'https://d222i9ppxs2fqe.cloudfront.net/holidays-giftbox.png';

export const NEW_GIFT_BOX =
  'https://d222i9ppxs2fqe.cloudfront.net/holidays-giftbox-2.png';

export const NEW_GIFT_CARD =
  'https://d222i9ppxs2fqe.cloudfront.net/holiday-gift-card.png';

export const NEW_GIFT_CARD_TWO =
  'https://d222i9ppxs2fqe.cloudfront.net/holiday-gift-card-2.png';

export const BESTSELLER_BUNDLE =
  'https://d222i9ppxs2fqe.cloudfront.net/bestsellerBundle.png';

export const HOLIDAY_GIFT_BOX_IMAGE =
  'https://d222i9ppxs2fqe.cloudfront.net/holiday_gift_box_image.jpeg';

export {
  headerList,
  baseHeaderList,
  footerList,
  baseFooterList,
  animationduration,
  orderFlowfooterContent,
};
