import checkbox from './checkbox';
import InputField from './Input';
import promoInput from './promoInput';
import Select from './Select';
import Radio from './Radio';
import AmountLabelInput from './AmountLabelInput';
import TextArea from './TextArea';
import MobileInputField from './MobileInputField';

const Controls = {
  InputField,
  Select,
  promoInput,
  checkbox,
  Radio,
  AmountLabelInput,
  TextArea,
  MobileInputField,
};

export default Controls;
